/* eslint-disable unicorn/filename-case */
import { SVGFileEnum } from '~/enums/SvgFileEnum';

const properties = {
    file: Object.values(SVGFileEnum),
    size: ['extra-small', 'small', 'normal', 'large', 'xlarge'],
    tag: ['span', 'a', 'button'],
    type: [null, 'button', 'submit'],
    variant: [null, 'secondary', 'yellow', 'green', 'red', 'inherit'],
};

export { properties };
